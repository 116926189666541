export const environment = {
    production: false,

    apiv2: 'https://lo-api-hmg.celsotech.io/',
    auth: "https://lo-authservice-hmg.celsotech.io/",
    api: 'https://lo-gatewayadmin-hmg.celsotech.io/',


    tinyMCE_key: 'j6n6nv1nte1qoly2azmdxwjialx5fnlqxwpeh3kxcdgl3zfb',
    tinyMCE_config: {
        language: 'pt_BR',
        token: null,
        inline: false,
        height: 370,
        width: 990,
        menubar: false,
        plugins: [
          'preview paste searchreplace autosave image link media hr anchor lists quickbars'
        ],
        toolbar:
          'undo redo | textoComTitulo image media download saibaMais citacao fraseDestaque fiquePorDentro bullist bibliografia ',
        external_plugins: {
          saibaMais: '/assets/plugins/saibaMais/saiba-mais-plugin.js',
          citacao: '/assets/plugins/citacao/citacao-plugin.js',
          ancora: '/assets/plugins/ancora/ancora-plugin.js',
          bibliografia: '/assets/plugins/bibliografia/bibliografia-plugin.js',
          download: '/assets/plugins/download/download-plugin.js',
          fiquePorDentro: '/assets/plugins/fiquePorDentro/fique-dentro-plugin.js',
          fraseDestaque: '/assets/plugins/fraseDestaque/frase-destaque-plugin.js',
          textoComTitulo: '/assets/plugins/textoComTitulo/texto-titulo-plugin.js',
          audio: '/assets/plugins/audio/audio-plugin.js',
        },
        autosave_interval: '30s',
        // contextmenu: 'image',
        keep_styles: false,
        quickbars_selection_toolbar: 'bold italic underline | copy cut paste',
        extended_valid_elements: 'svg[*],path[*],circle[*],line[*], img[*], iframe[*], video[*]',
        non_empty_elements: 'img,svg,defs,pattern,desc,metadata,g,mask,path,line,marker,rect,circle,ellipse,polygon,polyline,linearGradient,radialGradient,stop,image,view,text,textPath,title,tspan,glyph,symbol,switch,use,iframe, video',
        branding: false,
        image_caption: true,
        paste_word_valid_elements: 'b,strong,i,em,h1,h2,span,p',
        paste_enable_default_filters: false,
        quickbars_insert_toolbar: false,
        statusbar: false,
        media_filter_html: false,
        forced_root_block: 'p',
        forced_root_block_attrs: {
          'style': "font-size: 20px; letter-spacing: 0.1px; line-height: 1.65;"
        },
        content_css: "/assets/stylesheets/default-editor.css",
        protect: [
          /\<\/?(if|endif)\>/g,  // Protect <if> & </endif>
          /\<xsl\:[^>]+\>/g,  // Protect <xsl:...>
          /<\?php.*?\?>/g  // Protect php code
        ],
        advlist_bullet_styles: 'disc',
        setup: function (editor) {
          editor.on('ListMutation', function (e) {
          })
        },
        object_resizing: false

      }
};
